import * as React from "react";
import { useState, useEffect } from "react";
import {
  Create,
  SimpleForm,
  TextInput,
  required,
  useGetList,
  SelectInput,
  NumberInput
} from "react-admin";

const GameParametersCreateTitle = () => {
  return <h3 style={{ marginLeft: 15 }}>Create Game Parameters</h3>;
};

const GameParametersCreate = (props) => {
  const [isSelect, setIsSelect] = useState(false);
  const [gameId, setGameId] = useState('');
  const [selectedGame, setSelectedGame] = useState({});
  const [loading, setLoading] = useState(true);

  const { data } = useGetList("games", {
    pagination: { page: 1, perPage: 10000 },
    sort: { field: "name", order: "ASC" },
  });

  const handleSelectChange = (e) => {
    setIsSelect(e.target.value === 'select');
  }

  const onSuccess = () =>{
    const newUrl = `#/paramaters?filter=${JSON.stringify({ gameId: gameId })}`;
    window.location.assign(newUrl);
  }

  useEffect(() => {
    const fetchData = async () => {
      const url = window.location.hash;
      const urlParams = new URLSearchParams(url.split("?")[1]);
      const filter = JSON.parse(urlParams.get("filter"));
      const gameId = filter?.gameId;
      setGameId(gameId);

      const gameList = data?.map((item) => ({
        id: item.id,
        name: item.attributes.name,
        seoName: item.attributes.seoName,
      })) || [];

      const selectedGameData = gameList.find((game) => game.id === gameId);
      setSelectedGame(selectedGameData || {});
      setLoading(false);
    };

    fetchData();
  }, [data]);


  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <Create  {...props} mutationOptions={{onSuccess}}>
      <GameParametersCreateTitle sx={{ marginLeft: 20 }} />
      <SimpleForm >
        <TextInput source="id" disabled label="id" />
        <TextInput source="game" label="Game"  defaultValue={selectedGame.name} disabled validate={[required()]} />
        <TextInput source="gameId" label="GameId" defaultValue={gameId} disabled validate={[required()]} />
        <TextInput source="seoName" label="SeoName"defaultValue={selectedGame.seoName}  disabled validate={[required()]} />
        <TextInput source="label" label="Label" validate={[required()]} />
        <NumberInput source="priority" label="Priority" validate={[required()]} />
        <SelectInput source="type" choices={[
          { id: 'select', name: 'select' },
          { id: 'choice', name: 'choice' },
          { id: 'radio', name: 'radio' },
        ]}
          validate={[required()]}
          onChange={(event) => handleSelectChange(event)} />
        {
          isSelect && <>
            <TextInput source="value" label="Value" validate={[required()]} />
            <TextInput source="step" label="Step" validate={[required()]} />
            <TextInput source="minVal" label="Min. Value" validate={[required()]} />
            <TextInput source="maxVal" label="Max. Value" validate={[required()]} /></>
        }
      </SimpleForm>
    </Create>
  );
};

export default GameParametersCreate;
