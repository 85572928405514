import * as React from "react";
import { Link as Link2 } from "react-router-dom";
import { useRecordContext, ListButton } from "react-admin";
import { useState, useEffect } from "react";

const SingleRelationRecord = ({ path, name, icon, children }) => {
  const [filterQuiz, setFilterQuiz] = useState('');

  const record = useRecordContext();
  console.log('filterQuiz',filterQuiz)

  useEffect(() => {
    setFilterQuiz(`${JSON.stringify({ gameId: record.attributes.gameId, quizId: record.attributes.quizId })}`)
  }, [path === '/quiz/uploadCharacter'])

  return (
    <>
      <div>{children}</div>
      {record.attributes.type !== "select" ? (
        <span>
          <ListButton
            icon={icon}
            label={name}
            color="primary"
            sx={{ marginLeft: "-10px" }}
            component={Link2}
            to={{
              pathname: path,
              search: `filter=${ path === '/quiz/uploadCharacter'? filterQuiz : JSON.stringify({ paramid: record.id, multiGameId: record.attributes.gameId })}`,
            }}
          />
        </span>
      ) : (
        <ListButton disabled icon={icon} label={name} sx={{ marginLeft: "-10px" }} />
      )}
    </>
  );
};
export default SingleRelationRecord;
